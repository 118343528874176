<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-06-07 15:55:14
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-07 16:49:59
-->
<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" width="75%" v-model="visible" @ok="toSubmit" okText="提交" :maskClosable="false">
        <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
            <a-form-model-item label="功能类型名称" prop="name">
                <a-input v-model="rowData.name" placeholder="功能类型名称"></a-input>
            </a-form-model-item>
            <a-form-model-item label="排序" prop="(sort">
                <a-input v-model="rowData.sort" placeholder="排序"></a-input>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { editProductFunctionType, selectByIdProductFunctionType, addProductFunctionType } from '../api/ProductFunctionTypeApi'
export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            // 表单验证
            formRule: {
                name: [
                    { required: true, message: '请输入功能类型名称', trigger: 'blur' }
                ],
                sort: [
                    { required: true, message: '请输入排序', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.rowData = {}
            if(handle == 'edit') {
                selectByIdProductFunctionType(row.id).then(res => {
                    this.rowData = res.body
                })
            }
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                const res = this.handle === 'add' ? await addProductFunctionType(this.rowData) : await editProductFunctionType(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        }
    },
    created() {

    },

}
</script>

<style lang="less" scoped>

</style>
